/* eslint-disable */

export default {
  init() {
    // JavaScript to be fired on the home page
// Select video element
// const vid = document.querySelector('.scrub-frames #heroVid');

// // Constants
// const SCRUBBING_DIVISOR = 75; // Adjust this for scrubbing speed
// const SCROLL_TIMEOUT = 100; // Delay before pausing the video
// const SCRUB_INTERVAL = 40; // Interval for updating video frames

// let isPaused = false;

// // Function to handle video playback rate
// const handleScroll = () => {
//     if (!isPaused) {
//         vid.playbackRate = 0.5; // Reduce playback rate smoothly
//         isPaused = true;
//     }

//     // Clear the previous timeout if it's still active
//     clearTimeout(window.scrollTimeout);

//     // Set a timeout to pause the video after scrolling stops
//     window.scrollTimeout = setTimeout(() => {
//         vid.pause();
//         isPaused = false; // Reset pause state
//     }, SCROLL_TIMEOUT);
// };

// // Debounce scroll event to prevent excessive function calls
// const debounce = (func, delay) => {
//     let timeout;
//     return function(...args) {
//         clearTimeout(timeout);
//         timeout = setTimeout(() => func.apply(this, args), delay);
//     };
// };

// // Refresh video frames on interval for smoother playback
// setInterval(() => {
//     vid.currentTime = window.pageYOffset / SCRUBBING_DIVISOR; // Faster scrubbing
// }, SCRUB_INTERVAL);

// // Add scroll event listener
// window.addEventListener('scroll', debounce(handleScroll, 50));


// // Select the div you want to watch
// const heroVideo = document.querySelector('.hero-bkg-video');
// const hero = document.querySelector('.rpb-hero');

// // Get the middle of the window
// const windowHeight = window.innerHeight * .98;

// window.addEventListener('scroll', () => {
//   // Get the bottom position of the target div relative to the viewport
//   var divBottom = hero.getBoundingClientRect().bottom;

//   // Add classes when the bottom of the div passes the middle of the window
//   if (divBottom <= (windowHeight - 16)) {
//     heroVideo.classList.add('scale-down', 'rounded');
//     // heroVideo.classList.remove('clip-slant-bottom-right');
//     hero.classList.add('video-scaled','scrub-frames');
//   }
//   // Remove classes when scrolling back up
//   else {
//     heroVideo.classList.remove('scale-down', 'rounded');
//     // heroVideo.classList.add('clip-slant-bottom-right');
//     hero.classList.remove('video-scaled','scrub-frames');
//   }
// });




  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
