/* eslint-disable */

////////////
// Counter
////////////
document.addEventListener('DOMContentLoaded', function () {
  const counters = document.querySelectorAll('.counter');

  if (counters.length > 0) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver(function (entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const counter = entry.target;
          const targetValue = parseInt(counter.getAttribute('data-counter'));
          const duration = 2000; // 2 seconds
          const startTime = performance.now();
          const numberElement = counter.querySelector('.number');

          const updateCounter = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            const currentCount = Math.floor(progress * targetValue);

            numberElement.textContent = currentCount;

            if (progress < 1) {
              requestAnimationFrame(updateCounter);
            } else {
              numberElement.textContent = targetValue;
            }
          };

          requestAnimationFrame(updateCounter);
          observer.unobserve(counter);
        }
      });
    }, options);

    counters.forEach(counter => {
      observer.observe(counter);
    });
  }
});

////////////
// Cursor
////////////
const cursor = document.querySelector('.circle-cursor');
const button = document.querySelector('.button');
const cursorActive = $('a, .activate-cursor, .btn');

// Update cursor position on mousemove, accounting for scroll
document.addEventListener("mousemove", (event) => {
  cursor.style.left = `${event.clientX + window.scrollX - 10}px`;
  cursor.style.top = `${event.clientY + window.scrollY - 10}px`;
});

// Iterate through all the 'activateCursor' elements and add a listener to each one
for (let i = 0; i < cursorActive.length; i++) {
  // Change the size of the cursor while hovering over activation elements
  cursorActive[i].addEventListener("mouseover", (e) => {
    cursor.style.transform = 'scale(2, 2)';
  });
  // Change the cursor back to normal on mouse out
  cursorActive[i].addEventListener("mouseout", (e) => {
    cursor.style.transform = 'scale(1, 1)';
  });
}

////////////
// Circular Text
////////////

const text = document.getElementById("image-text");
if (text) {
  text.innerHTML = text.innerText
    .split("")
    .map(
      (char, i) =>
        `<span class="char" style="transform: rotate(${
          i * 14.4 // divide by # of characters
        }deg);">${char}</span>`
    )
    .join("");
}

////////////
// Magnetic Gradient
// Causes div to follow mouse while in viewport to create a spotlight type effect
////////////
var blobs = document.querySelectorAll('.blob');

document.addEventListener('mousemove', function(e) {
  var x = e.clientX;
  var y = e.clientY;

  blobs.forEach(function(blob) {
    // Get the blob's position relative to the viewport
    var rect = blob.getBoundingClientRect();
    var blobWidth = rect.width;
    var blobHeight = rect.height;

    // Get the parent container of the blob
    var parent = blob.parentElement;
    var parentRect = parent.getBoundingClientRect();

    // Calculate the mouse position relative to the parent container
    var relativeX = x - parentRect.left;
    var relativeY = y - parentRect.top;

    // Center the blob at the mouse position inside its parent
    var centerX = relativeX - blobWidth / 2;
    var centerY = relativeY - blobHeight / 2;

    // Apply the transform to center the blob at the cursor inside the parent container
    blob.style.transform = `translate3d(${centerX}px, ${centerY}px, 0)`;
  });
});

